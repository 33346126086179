import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Kjo lojë është ndërtuar nga -{' '}
        <a
          href="https://elif.al"
          className="underline font-bold"
        >
          ELIF.al
        </a>{' '}
      </p>
    </BaseModal>
  )
}
