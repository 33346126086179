export const GAME_TITLE = 'WORDL Shqip'

export const WIN_MESSAGES = ['Shumë mirë!', 'Bravo', 'Të Lumtë!']
export const GAME_COPIED_MESSAGE = 'Loja u kopjua në clipboard'
export const ABOUT_GAME_MESSAGE = 'Rreth kësaj loje'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Nuk mjaftojnë shkronjat'
export const WORD_NOT_FOUND_MESSAGE = 'Kjo fjalë nuk është e vlefshme për këtë lojë'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Fjala ka ${solution}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Fshi'
export const STATISTICS_TITLE = 'Statistika'
export const GUESS_DISTRIBUTION_TEXT = 'Shpërndarja e Gjetjeve'
export const NEW_WORD_TEXT = 'Fjala e re në'
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'Provat Totale'
export const SUCCESS_RATE_TEXT = 'Provat e Suksesshme'
export const CURRENT_STREAK_TEXT = 'Aktuale'
export const BEST_STREAK_TEXT = 'Rekordi'
