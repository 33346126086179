export const WORDS = [
  "vetem",
"njeri",
"janar",
"pjese",
"krejt",
"teper",
"asgje",
"sakte",
"sipas",
"perse",
"rreth",
"asnje",
"rruge",
"qofte",
"fakti",
"besim",
"takim",
"fjala",
"linja",
"qarte",
"vetes",
"shkak",
"lehte",
"hapur",
"erdhi",
"liber",
"Prita",
"vogel",
"pjese",
"kater",
"ndare",
"raste",
"djali",
"nisur",
"prane",
"kurre",
"prite",
"rende",
"shoke",
"arsye",
"larte",
"linje",
"Ramiz",
"bukur",
"rruga",
"dikur",
"kryer",
"lajme",
"marka",
"sakte",
"vendi",
"letra",
"plote",
"radhe",
"tetor",
"burgu",
"mjaft",
"siper",
"miqte",
"plaku",
"forca",
"forma",
"lufta",
"numer",
"prapa",
"tende",
"trete",
"tutje",
"barre",
"besoj",
"kurse",
"leter",
"leviz",
"metra",
"balle",
"dukja",
"futur",
"ideja",
"mbare",
"midis",
"nenat",
"rasti",
"rreme",
"thyer",
"vetmi"

]
