import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Si funksionon loja" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      Gjej fjalën në 6 prova. Pas çdo prove, ngjyra e katrëve do
         ndryshojë për të treguar se sa afër ishte pova me fjalën. 
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="F" status="correct" />
        <Cell value="J" />
        <Cell value="A" />
        <Cell value="L" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Shkronja F është në vendin e duhur
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="R" />
        <Cell value="O" status="present" />
        <Cell value="V" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
          Shkronja O është në fjalë por jo në vendin e duhur
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="F" />
        <Cell value="O" />
        <Cell value="R" />
        <Cell value="C" status="absent" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
         Shkronja C nuk është as në fjalë në asnjë vendin
      </p>
    </BaseModal>
  )
}
